import styled from "styled-components";

export const Wrapper = styled.section`
  height: 90vh;
  padding: 2.5vw 2.5vw 2.5vw 4vw;
  margin-top: 54px;
  display: flex;
  flex-direction: column;
`

export const ContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: calc(100% - 76px);
`

export const Title = styled.h2`
  margin-bottom: 24px;
  font-family: TT Norms, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 42px;
  font-feature-settings: 'liga' off;
  color: #FFFFFF;
  padding-bottom: 9px;
  border-bottom: 1px solid #FFFFFF;
`
export const NavList = styled.ul`
  list-style: none;
  margin-top: 4px;
  margin-right: 8px;
`
export const NavItem = styled.li`
  font-family: TT Norms, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  font-feature-settings: 'liga' off;
  color: #FFFFFF;
  margin-bottom: 9px;
  opacity: 0.25;
  cursor: pointer;
  transition: opacity 500ms ease-in-out;

  &.active, &:hover {
    opacity: 1;
  }
`
export const ImageWrapper = styled.div`
  height: 100%;
  width: 77%;
  max-width: 1000px;
  position: relative;
`
export const MapImageContainer = styled.div`
  opacity: 0;

`
export const FirstMapText = styled.div`
  position: absolute;
  top: 21px;
  left: 24px;

  font-family: 'TT Norms', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 30px;
  color: #151515;
  max-width: 305px;

  span {
    font-family: 'Adobe-Caslon-Pro', sans-serif;
    font-style: italic;
    color: #151515;
  }
`


export const MarkersContainer = styled.div`
  position: absolute;
  bottom: 10px;
  left: 24px;
`
export const Marker = styled.div`
  font-family: 'TT Norms', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16.52px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  color: #151515;

  span {
    width: 18px;
    height: 18px;
    margin-right: 6px;
    display: inline-block;
    border-radius: 50%;

    &.orange {
      background: #FAA71E;
    }

    &.blue {
      background: #0E6C79;
    }
  }
`

export const SecondMapText = styled.div`
  position: absolute;
  bottom: 7px;
  left: 24px;
`

export const MapSubtitle = styled.div`
  color: #151515;
  font-family: 'TT Norms', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16.52px;
  margin-bottom: 14px;
`
export const MapTitle = styled.div`
  color: #151515;
  font-family: 'Adobe-Caslon-Pro', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 28.8px;
  margin-bottom: 10px;
`

export const ThirdMapText = styled.div`
  position: absolute;
  bottom: 13px;
  right: 29px;
  font-family: TT Norms, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 36px;
  color: #292929;
  max-width: 290px;

  span {
    font-family: 'Adobe-Caslon-Pro', sans-serif;
    font-style: italic;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    color: #292929;
  }
`

export const MapImage = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
  pointer-events: none;
  font-family: 'Adobe-Caslon-Pro', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 28.8px;
`