import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  position: relative;
  margin: 34px 0;
  height: 160vw;
`

export const MapItem = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: visible;
  transition: .5s ease-out;
`

export const MapImage = styled.div`
  img {
    display: block;
  }  
`

export const MapInfo = styled.div`
  padding: 17px 24px 24px;
  min-height: 230px;
  background: #fff;
  h2 {
    color: #292929;
  }
`
export const Info = styled.div`
  padding: 20px 0 0;
  .map-heading {
    font-family: "TT Norms", serif;
    font-weight: 300;
    font-size: 24px;
    line-height: 1.3;
    max-width: 340px;
    &.italic {
      font-family: "Adobe Caslon Pro", serif;
      font-style: italic;
      font-weight: 400;
      line-height: 1.4;
    }
    &.bolder {
      font-weight: 400;
      padding-top: 20px;
    }
  }
  .map-ingress-heading {
    font-family: "Adobe Caslon Pro", serif;
    font-weight: 400;
    font-size: 22px;
    line-height: 1.3;
    padding-bottom: 6px;
    color: #292929;
    &.subheading {
      font-family: 'TT Norms', serif;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.1em;
      padding-bottom: 12px;
    }
  }
  .marker-item {
    p {
      font-family: "TT Norms", serif;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      color: #292929;
      position: relative;
    }
    .blue {
      padding: 0 20px;
      &:before {
        content: '';
        width: 14px;
        height: 14px;
        border-radius: 50%;
        background-color: #0E6C79;
        position: absolute;
        left: 0;
      }
    }
    .orange {
      padding: 20px 20px 12px;
      &:before {
        content: '';
        width: 14px;
        height: 14px;
        border-radius: 50%;
        background-color: #FAA71E;
        position: absolute;
        left: 0;
      }
    }
  }
`


export const MapNavSection = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: 'TT Norms', serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  font-feature-settings: 'liga' off;
  p {
    color: #151515;
    opacity: .25;
    transition: .6s ease-out;
    cursor: pointer;
    pointer-events: all;
    &:hover {
      opacity: .55;
    }
    &.active {
      opacity: 1;
    }
  }
`


