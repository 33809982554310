import styled from "styled-components"
import { mediaMax } from "../../helpers/MediaQueries"

export const Wrapper = styled.div`
  width: 100%;
  padding: 10vh 10%;
  background-color: #151515;
  overflow: hidden;
  
  ${mediaMax.phoneXL`
    height: 100%;
    padding: 50px 24px 0;
  `}
`

export const FlexRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  
  > div {
    margin-right: 10px;
    &:last-child {
      margin-right: 0;
    }
  }
  
  ${mediaMax.phoneXL`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
  `}
`

export const GridRow = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 10%;
  row-gap: 5vh;
  ${mediaMax.phoneXL`
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
  `}
`

export const TitleRow = styled.div`
  display: flex;
  width: 100%;
  border-bottom: 2px solid white;
  margin-bottom: 2vh;
  margin-top: 5vh;
  p {
    text-transform: uppercase;
    letter-spacing: 0.15em;
    font-feature-settings: "liga" off;
    ${mediaMax.phoneXL`
      margin-bottom: 9px;
      font-weight: 300;
    `}
  }
  ${mediaMax.phoneXL`
    margin-top: 60px;
    margin-bottom: 40px;
  `}
`

export const Box = styled.div<{ alignBottom: boolean }>`
  display: flex;
  align-items: ${props => (props.alignBottom ? "flex-end" : "center")};
  justify-content: center;
  ${mediaMax.phoneXL`
    margin: 0 auto!important;
    &.logo-h-0 {width: 131px}
    &.logo-h-1 {width: 155px}
    &.logo-h-2 {width: 127px}
    &.logo-h-3 {width: 72px}
    &.logo-h-4 {width: 131px}
    &.logo-h-5 {width: 52px}
    
    &.logo-d-0 {width: 36px}
    &.logo-d-1 {width: 60px}
    &.logo-d-2 {width: 60px}
    &.logo-d-3 {width: 65px}
    &.logo-d-4 {width: 75px}
    &.logo-d-5 {width: 116px}
    &.logo-d-6 {width: 92px}
    &.logo-d-7 {width: 83px}
    &.logo-d-8 {width: 111px}
    &.logo-d-9 {width: 69px}
    &.logo-d-10 {width: 112px}
    &.logo-d-11 {width: 79px}
    &.logo-d-12 {width: 69px}
    &.logo-d-13 {width: 119px}
    &.logo-d-14 {width: 47px}
    &.logo-d-15 {width: 121px}
  `}
`
