import React, {useEffect, useRef} from "react"
import gsap from "gsap"
import {
  ContentContainer,
  FirstMapText,
  ImageWrapper,
  MapImage,
  MapImageContainer,
  NavItem,
  NavList,
  SecondMapText,
  ThirdMapText,
  Title,
  Wrapper,
  MapSubtitle,
  MapTitle,
  Marker,
  MarkersContainer
} from "./index.styled";

import mapImage1 from "../../../assets/images/neighborhood/map-1.png"
import mapImage2 from "../../../assets/images/neighborhood/map-2.png"
import mapImage3 from "../../../assets/images/neighborhood/map-3.png"

const InteractiveMap = () => {
  const images = useRef<HTMLImageElement[]>([])
  const navListRef = useRef<HTMLUListElement>(null)

  useEffect(() => {
    gsap.to(images.current[0], {opacity: 1})
  }, [])

  const handleChangeSlide = (element: React.MouseEvent<HTMLLIElement>, index: number) => {
    if (navListRef?.current)
      for (const elem of navListRef?.current?.children) {
        elem?.classList.remove("active")
      }
    element.currentTarget.classList.add("active")

    const imagesTimeline = gsap.timeline()
    images.current.forEach((el, key) => {
      if (key === index) {
        imagesTimeline.to(el, {
          opacity: 1
        }, "0")
      } else {
        imagesTimeline.to(el, {
          opacity: 0
        }, "0")
      }
    })
  }

  const addImagesToRefs = (el: HTMLImageElement) => {
    if (el && !images?.current?.includes(el)) {
      images?.current?.push(el)
    }
  }
  return (
    <Wrapper>
      <Title>
        Neighborhood Map
      </Title>
      <ContentContainer>
        <NavList ref={navListRef}>
          <NavItem onClick={(el) => handleChangeSlide(el, 0)} className="active">Ingress & Egress</NavItem>
          <NavItem onClick={(el) => handleChangeSlide(el, 1)}>Walkable Amenities</NavItem>
          <NavItem onClick={(el) => handleChangeSlide(el, 2)}>Arrivals & Departures</NavItem>
        </NavList>
        <ImageWrapper>
          <MapImageContainer ref={addImagesToRefs}>
            <MapImage src={mapImage2}/>
            <SecondMapText>
              <MapSubtitle>
                DIRECT ACCESS TO
              </MapSubtitle>
              <MapTitle>
                Dallas North Tollway
              </MapTitle>
              <MapTitle>
                Turtle Creek Blvd
              </MapTitle>
              <MapTitle>
                North Central Expressway (NCX/75)
              </MapTitle>
            </SecondMapText>
          </MapImageContainer>

          <MapImageContainer ref={addImagesToRefs}>
            <MapImage src={mapImage1}/>
            <FirstMapText>
              Enjoy Uptown’s walkable amenities and Turtle Creek’s
              <br/>
              <span>
                Verdant Landscape.
              </span>
            </FirstMapText>
            <MarkersContainer>
              <Marker>
                <span className={"orange"}/>
                RESTAURANTS
              </Marker>
              <Marker>
                <span className={"blue"}/>
                MUSEUMS & ENTERTAINMENT
              </Marker>
            </MarkersContainer>
          </MapImageContainer>
          <MapImageContainer ref={addImagesToRefs}>
            <MapImage src={mapImage3}/>
            <ThirdMapText>
              Maple Terrace makes arrivals and departures easy
              <br/>
              <span>because minutes matter.</span>
            </ThirdMapText>
          </MapImageContainer>
        </ImageWrapper>
      </ContentContainer>
    </Wrapper>
  )
}

export default InteractiveMap
