import React, {FC, useEffect, useRef} from 'react';
import {Wrapper, MapItem, MapImage, MapInfo, Info, MapNavSection} from './index.styled'
import mapNeighborhood from '../../../assets/images/neighborhood/map-neighborhood.jpg'
import mapIngress from '../../../assets/images/neighborhood/map-ingress.jpg'
import mapAirport from '../../../assets/images/neighborhood/map-airport.jpg'

const InteractiveMapMob:FC = () => {
  const map1Ref = useRef<HTMLDivElement>(null);
  const map2Ref = useRef<HTMLDivElement>(null);
  const map3Ref = useRef<HTMLDivElement>(null);

  const fpItemShow = (fpItem: React.RefObject<HTMLDivElement>) => {
    if(fpItem.current) fpItem.current.style.opacity = '1';
    if(fpItem.current) fpItem.current.style.visibility = 'visible';
  }
  const fpItemHide = (fpItem: React.RefObject<HTMLDivElement>) => {
    if(fpItem.current) fpItem.current.style.opacity = '0';
    if(fpItem.current) fpItem.current.style.visibility = 'hidden';
  }

  useEffect(()=> {
    fpItemShow(map1Ref)
  }, [])
  return (
    <>
      <Wrapper>
        <MapItem ref={map1Ref}>
          <MapImage>
            <img src={mapNeighborhood} alt="Maple Terrace The Neighborhood Map"/>
          </MapImage>
          <MapInfo>
            <MapNavSection>
              <p className={"map-1 active"} onClick= {() => {fpItemShow(map1Ref);fpItemHide(map2Ref);fpItemHide(map3Ref);}}>The Neighborhood</p>
              <p className={"map-2"} onClick= {()=> {fpItemShow(map2Ref); fpItemHide(map1Ref); fpItemHide(map3Ref);}}>Ingress & Egress</p>
              <p className={"map-3"} onClick= {()=> {fpItemShow(map3Ref); fpItemHide(map1Ref); fpItemHide(map2Ref);}}>Arrivals & Departures</p>
            </MapNavSection>
            <Info>
              <h2 className={'map-heading'}>
                Enjoy Uptown’s walkable amenities and Turtle Creek’s
              </h2>
              <h2 className={'map-heading italic'}>
                Verdant Landscape.
              </h2>
              <div className="marker-item">
                <p className={'orange'}>RESTAURANTS</p>
                <p className={'blue'}>MUSEUMS & ENTERTAINMENT</p>
              </div>
            </Info>
          </MapInfo>
        </MapItem>
        <MapItem ref={map2Ref}>
          <MapImage>
            <img src={mapIngress} alt="Maple Terrace The Neighborhood Map"/>
          </MapImage>
          <MapInfo>
            <MapNavSection>
              <p className={"map-1"} onClick= {() => {fpItemShow(map1Ref);fpItemHide(map2Ref);fpItemHide(map3Ref);}}>The Neighborhood</p>
              <p className={"map-2 active"} onClick= {()=> {fpItemShow(map2Ref); fpItemHide(map1Ref); fpItemHide(map3Ref);}}>Ingress & Egress</p>
              <p className={"map-3"} onClick= {()=> {fpItemShow(map3Ref); fpItemHide(map1Ref); fpItemHide(map2Ref);}}>Arrivals & Departures</p>
            </MapNavSection>
            <Info>
              <h5 className={'map-ingress-heading subheading'}>DIRECT ACCESS TO</h5>
              <h2 className={'map-ingress-heading'}>Dallas North Tollway</h2>
              <h2 className={'map-ingress-heading'}>Turtle Creek Blvd</h2>
              <h2 className={'map-ingress-heading'}>North Central Expressway (NCX/75)</h2>
            </Info>
          </MapInfo>
        </MapItem>
        <MapItem ref={map3Ref}>
          <MapImage>
            <img src={mapAirport} alt="Maple Terrace The Neighborhood Map"/>
          </MapImage>
          <MapInfo>
            <MapNavSection>
              <p className={"map-1"} onClick={() => {fpItemShow(map1Ref);fpItemHide(map2Ref);fpItemHide(map3Ref);}}>The Neighborhood</p>
              <p className={"map-2"} onClick={()=> {fpItemShow(map2Ref); fpItemHide(map1Ref); fpItemHide(map3Ref);}}>Ingress & Egress</p>
              <p className={"map-3 active"} onClick={()=> {fpItemShow(map3Ref); fpItemHide(map1Ref); fpItemHide(map2Ref);}}>Arrivals & Departures</p>
            </MapNavSection>
            <Info>
              <h2 className={'map-heading bolder'}>
                Maple Terrace makes arrivals and departures easy
              </h2>
              <h2 className={'map-heading italic'}>
                because minutes matter.
              </h2>
            </Info>
          </MapInfo>
        </MapItem>


      </Wrapper>
    </>
  );
};

export default InteractiveMapMob;