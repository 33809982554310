import React from "react";
import {quoteData} from "../data/intro"
import {partners} from "../data/partners"
import {spacesData} from "../data/spaces"
import {nextData} from "../data/next"
import {ImgTextData} from "../data/img-text"
import {withinWalkData} from "../data/sliders"
import {headText} from "../data/head-text"
import ContentQuote from "../components/content-quote"
import ContentIntro from "../components/content-intro"
import ContentNextSection from "../components/content-next"
import ContentImgText from "../components/content-img-text"
import Footer from "../components/footer"
import ContentSlider from "../components/content-slider"
import ContentPartners from "../components/content-partners"
import InteractiveMap from "../components/neighborhood/interactive-map";
import InteractiveMapMob from "../components/neighborhood/interactive-map-mob";
import ContentHeadingTextImage from "../components/content-heading-text-image";
import ContentMaskedFixed from "../components/content-masked-fixed";
import useWindowSize from "../helpers/windowSize";
import ContentMasked from "../components/content-masked";
import ContentSpaces from "../components/amenities/content-spaces";
import neighborhoodMaskedImage from "../assets/images/neighborhood/neighborhood_5.jpg";

const NeighborhoodPage = () => {
  const {width} = useWindowSize();
  return (
    <>
      <ContentIntro imgName={width > 576 ? "neighborhood_1.jpg" : "neighborhood_1_mob.jpg"} objPosition={'center 35% !important'} scrollArrow={true}/>
      <section  id={'nextToIntro'} className={"hide-logo"}>
        <ContentQuote quote={quoteData.neighborhood1}/>
      </section>
      {width > 1023 ?
        <InteractiveMap/>
        :
        <InteractiveMapMob/>
      }
      <ContentImgText data={ImgTextData.neighborhood1} letterSpacing={'0'} mobFullWidth={true} isHeightAuto={true}/>
      <ContentSpaces spaces={spacesData.neighborhood1}/>
      {width > 1023 ?
        <ContentMaskedFixed imgSrc={neighborhoodMaskedImage}/>
        :
        <ContentMasked imgName={"neighborhood_5_mob.jpg"}/>
      }
      <ContentSlider
        data={withinWalkData.slides}
        title={withinWalkData.title}
      />
      <ContentHeadingTextImage
        heading={headText.neighborhood.heading}
        headingWidth={'710px'}
        logo={headText.neighborhood.logo}
        image={"neighborhood_6.jpg"}
      />
      <ContentPartners items={partners}/>
      <ContentNextSection data={nextData.neighborhood} prevBtn={true}/>
      <Footer/>
    </>
  )
}

export default NeighborhoodPage
